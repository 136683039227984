<template>
  <div>
    <div id="main__content">
      <div class="x-main-container">
        <div class="x-main-side-bar">
          <div class="x-menu-provider js-tab-menu-provider -desktop-view">
            <nav
              :style="{
                background:
                  settings && settings.game_color
                    ? settings.game_color.hex
                    : '',
              }"
              id="navbarCategory"
              class="nav-menu"
            >
              <ul class="nav nav-pills js-menu-container -nav-menu-container">
                <li class="nav-item ng-star-inserted">
                  <button
                    class="nav-link -casino"
                    :style="{
                      backgroundColor:
                        settings && settings.nav_button_color
                          ? settings.nav_button_color.hex
                          : '',
                      background:
                        settings && settings.nav_button_color
                          ? settings.nav_button_color.hex
                          : '',
                    }"
                    :class="{ active: activeMenu1 }"
                    @click="getProduct('EGAMES'), toggleActive('EGAMES')"
                  >
                    <img
                      alt="สล็อตเกม"
                      width="55"
                      height="55"
                      class="img-fluid -ic-menu"
                      :src="require('@/assets/newImg/slot.png')"
                    />
                    <div class="-text-provider-wrapper">
                      <div class="-text-nav-menu -title">SLOT</div>
                      <div class="-text-nav-menu -title-trans">สล็อตเกม</div>
                      <div class="-text-nav-menu -title-mobile">สล็อตเกม</div>
                    </div>
                  </button>
                </li>

                <li class="nav-item ng-star-inserted">
                  <button
                    class="nav-link -casino"
                    :style="{
                      backgroundColor:
                        settings && settings.nav_button_color
                          ? settings.nav_button_color.hex
                          : '',
                      background:
                        settings && settings.nav_button_color
                          ? settings.nav_button_color.hex
                          : '',
                    }"
                    :class="{ active: activeMenu2 }"
                    @click="
                      getProduct('LIVECASINO'), toggleActive('LIVECASINO')
                    "
                  >
                    <img
                      alt="คาสิโนสด"
                      width="55"
                      height="55"
                      class="img-fluid -ic-menu"
                      :src="require('@/assets/newImg/casino.webp')"
                    />
                    <div class="-text-provider-wrapper">
                      <div class="-text-nav-menu -title">CASINO</div>
                      <div class="-text-nav-menu -title-trans">คาสิโนสด</div>
                      <div class="-text-nav-menu -title-mobile">คาสิโนสด</div>
                    </div>
                  </button>
                </li>

                <li class="nav-item ng-star-inserted">
                  <button
                    class="nav-link -casino"
                    :style="{
                      backgroundColor:
                        settings && settings.nav_button_color
                          ? settings.nav_button_color.hex
                          : '',
                      background:
                        settings && settings.nav_button_color
                          ? settings.nav_button_color.hex
                          : '',
                    }"
                    :class="{ active: activeMenu3 }"
                    @click="getProduct('SPORT'), toggleActive('SPORT')"
                  >
                    <img
                      alt="กีฬา"
                      width="55"
                      height="55"
                      class="img-fluid -ic-menu"
                      :src="
                        require('@/assets/newImg/แทงฟุตบอลพนันออนไลน์-บาสเก็ตบอล-E-Sport.png')
                      "
                    />
                    <div class="-text-provider-wrapper">
                      <div class="-text-nav-menu -title">SPORT</div>
                      <div class="-text-nav-menu -title-trans">กีฬา</div>
                      <div class="-text-nav-menu -title-mobile">กีฬา</div>
                    </div>
                  </button>
                </li>

                <li class="nav-item ng-star-inserted">
                  <button
                    class="nav-link -casino"
                    :style="{
                      backgroundColor:
                        settings && settings.nav_button_color
                          ? settings.nav_button_color.hex
                          : '',
                      background:
                        settings && settings.nav_button_color
                          ? settings.nav_button_color.hex
                          : '',
                    }"
                    :class="{ active: activeMenu4 }"
                    @click="getProduct('LOTTO'), toggleActive('LOTTO')"
                  >
                    <img
                      alt="หวย"
                      width="55"
                      height="55"
                      class="img-fluid -ic-menu"
                      :src="require('@/assets/newImg/lotto.png')"
                    />
                    <div class="-text-provider-wrapper">
                      <div class="-text-nav-menu -title">LOTTO</div>
                      <div class="-text-nav-menu -title-trans">หวย</div>
                      <div class="-text-nav-menu -title-mobile">หวย</div>
                    </div>
                  </button>
                </li>

                <li class="nav-item ng-star-inserted">
                  <button
                    class="nav-link -casino"
                    :style="{
                      backgroundColor:
                        settings && settings.nav_button_color
                          ? settings.nav_button_color.hex
                          : '',
                      background:
                        settings && settings.nav_button_color
                          ? settings.nav_button_color.hex
                          : '',
                    }"
                    :class="{ active: activeMenu5 }"
                    @click="getProduct('FISHING'), toggleActive('FISHING')"
                  >
                    <img
                      alt="ยิงปลา"
                      width="55"
                      height="55"
                      class="img-fluid -ic-menu"
                      :src="require('@/assets/newImg/quick-nav-mpg.png')"
                    />
                    <div class="-text-provider-wrapper">
                      <div class="-text-nav-menu -title">FISHING</div>
                      <div class="-text-nav-menu -title-trans">ยิงปลา</div>
                      <div class="-text-nav-menu -title-mobile">ยิงปลา</div>
                    </div>
                  </button>
                </li>

                <li class="nav-item ng-star-inserted">
                  <button
                    class="nav-link -casino"
                    :style="{
                      backgroundColor:
                        settings && settings.nav_button_color
                          ? settings.nav_button_color.hex
                          : '',
                      background:
                        settings && settings.nav_button_color
                          ? settings.nav_button_color.hex
                          : '',
                    }"
                    :class="{ active: activeMenu6 }"
                    @click="getProduct('CARD'), toggleActive('CARD')"
                  >
                    <img
                      alt="เกมการ์ด"
                      width="55"
                      height="55"
                      class="img-fluid -ic-menu"
                      :src="
                        require('@/assets/newImg/คาสิโนออนไลน์-บาคาร่าสด-รูเล็ต-ซิคโบ-ไฮโล-เสือ-มังกร.png')
                      "
                    />
                    <div class="-text-provider-wrapper">
                      <div class="-text-nav-menu -title">G-CARD</div>
                      <div class="-text-nav-menu -title-trans">เกมการ์ด</div>
                      <div class="-text-nav-menu -title-mobile">เกมการ์ด</div>
                    </div>
                  </button>
                </li>

                <li class="nav-item ng-star-inserted">
                  <button
                    class="nav-link -casino"
                    :style="{
                      backgroundColor:
                        settings && settings.nav_button_color
                          ? settings.nav_button_color.hex
                          : '',
                      background:
                        settings && settings.nav_button_color
                          ? settings.nav_button_color.hex
                          : '',
                    }"
                    :class="{ active: activeMenu8 }"
                    @click="getProduct('POKER'), toggleActive('POKER')"
                  >
                    <img
                      alt="โป๊กเกอร์"
                      width="55"
                      height="55"
                      class="img-fluid -ic-menu"
                      :src="require('@/assets/newImg/poker.png')"
                    />
                    <div class="-text-provider-wrapper">
                      <div class="-text-nav-menu -title">POKER</div>
                      <div class="-text-nav-menu -title-trans">โป๊กเกอร์</div>
                      <div class="-text-nav-menu -title-mobile">โป๊กเกอร์</div>
                    </div>
                  </button>
                </li>

                <li class="nav-item ng-star-inserted">
                  <button
                    class="nav-link -casino"
                    :style="{
                      backgroundColor:
                        settings && settings.nav_button_color
                          ? settings.nav_button_color.hex
                          : '',
                      background:
                        settings && settings.nav_button_color
                          ? settings.nav_button_color.hex
                          : '',
                    }"
                    :class="{ active: activeMenu7 }"
                    @click="getProduct('TRADING'), toggleActive('TRADING')"
                  >
                    <img
                      alt="กีฬา"
                      width="55"
                      height="55"
                      class="img-fluid -ic-menu"
                      :src="require('@/assets/newImg/trading.png')"
                    />
                    <div class="-text-provider-wrapper">
                      <div class="-text-nav-menu -title">TRADING</div>
                      <div class="-text-nav-menu -title-trans">เทรดดิ้ง</div>
                      <div class="-text-nav-menu -title-mobile">เทรดดิ้ง</div>
                    </div>
                  </button>
                </li>
              </ul>
              <div class="-contact-wrapper">
                <div class="x-contact-us -text">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    class="-line-wrapper"
                    :href="agent.linecontact"
                  >
                    <img
                      src="@/assets/newImg/ic-line-text.png"
                      alt="รูปไอคอนไลน์"
                      width="160"
                      height="51"
                      class="-line-img"
                  /></a>
                </div>
              </div>
            </nav>
          </div>
        </div>
        <div class="x-main-content">
          <div class="d-none d-lg-block">
            <div class="x-feed-news-header">
              <div
                data-animatable="fadeInUp"
                data-delat="200"
                class="-feed-news-inner-wrapper animated fadeInUp"
              >
                <!-- <div class="-icon-container">
                  <i class="fas fa-volume" />
                </div> -->
                <div class="-track">
                  <div
                    :style="{
                      background:
                        settings && settings.announce_color
                          ? settings.announce_color.hex
                          : '',
                      color:
                        settings && settings.announce_text_color
                          ? settings.announce_text_color.hex
                          : '',
                    }"
                    class="-track-item -duration-normal-content"
                  >
                    ...
                    {{ agent.announce }}
                    &nbsp;..
                  </div>
                </div>
              </div>
            </div>
          </div>
          <section class="x-index-top-container position-relative pb-4">
            <div
              class="bg-main"
              :style="{
                background:
                  settings && settings.bg
                    ? `url(${settings.bg}) no-repeat 100% center`
                    : '',
              }"
            />
            <div class="x-wrapper-right-container">
              <div class="-index-inner-wrapper">
                <div
                  class="d-block d-md-none d-lg-none"
                  v-if="
                    settings && settings.slides && settings.slides.length > 0
                  "
                >
                  <swiper
                    class="swiper-autoplay"
                    :options="swiperOptions"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  >
                    <swiper-slide
                      v-for="(data, index) in settings.slides"
                      :key="index"
                    >
                      <b-img :src="data" fluid style="border-radius: 10px" />
                    </swiper-slide>
                  </swiper>
                </div>
                <div class="d-lg-none">
                  <div class="x-feed-news-header">
                    <div
                      data-animatable="fadeInUp"
                      data-delat="200"
                      class="-feed-news-inner-wrapper animated fadeInUp"
                    >
                      <div class="-icon-container">
                        <i class="fas fa-volume-up" />
                      </div>
                      <div class="-track">
                        <div
                          :style="{
                            background:
                              settings && settings.announce_color
                                ? settings.announce_color.hex
                                : '',
                          }"
                          class="-track-item -duration-normal-content"
                        >
                          ...
                          {{ agent.announce }}
                          &nbsp;..
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="ng-star-inserted">
                  <div class="-games-provider-wrapper">
                    <div
                      class="-menu-index-page"
                      style="position: relative; overflow: hidden"
                    >
                      <div class="sticksy-dummy-node">
                        <div class="x-menu-provider js-tab-menu-provider">
                          <nav
                            :style="{
                              background:
                                settings && settings.game_color
                                  ? settings.game_color.hex
                                  : '',
                            }"
                            id="navbarCategory"
                            class="nav-menu"
                          >
                            <ul
                              class="nav nav-pills js-menu-container -nav-menu-container"
                            >
                              <li
                                v-for="item in gameListOp"
                                :key="item.id"
                                class="nav-item ng-star-inserted"
                              >
                                <button
                                  routerlinkactive="active"
                                  class="nav-link"
                                  tabindex="0"
                                  @click="getProduct(item.cat)"
                                >
                                  <img
                                    :alt="item.alt"
                                    width="55"
                                    height="55"
                                    class="img-fluid -ic-menu"
                                    :src="item.icon"
                                  />
                                  <div class="-text-provider-wrapper">
                                    <div class="-text-nav-menu -title">
                                      {{ item.name_en }}
                                    </div>
                                    <div class="-text-nav-menu -title-trans">
                                      {{ item.name_th }}
                                    </div>
                                    <div class="-text-nav-menu -title-mobile">
                                      {{ item.name_th }}
                                    </div>
                                  </div>
                                </button>
                              </li>
                            </ul>
                            <div class="-contact-wrapper">
                              <div class="x-contact-us -text">
                                <a
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  class="-line-wrapper"
                                  :href="agent.linecontact"
                                  ><img
                                    src="@/assets/newImg/ic-line-text.png"
                                    alt="รูปไอคอนไลน์"
                                    width="160"
                                    height="51"
                                    class="-line-img"
                                /></a>
                              </div>
                            </div>
                          </nav>
                        </div>
                      </div>
                    </div>
                    <div class="-games-index-page">
                      <div
                        class="x-category-provider js-game-scroll-container js-game-container"
                      >
                        <div class="-games-list-container container">
                          <div class="x-hot-games-container">
                            <div class="ng-star-inserted">
                              <div
                                class="x-category-provider js-game-scroll-container js-game-container"
                              >
                                <!-- <div class="-top-paragraph">
                                  <div class="container">
                                    <h2 class="-h2">
                                      <span>LOTTO</span> ที่ดีที่สุดสำหรับคุณแบบง่ายๆ
                                      ที่จะช่วยให้คุณได้เว็บซื้อหวยออนไลน์ บน Power100TH
                                    </h2>
                                    <p class="-h3">
                                      แทงหวยได้หลายประเภท เพิ่มอัตราจ่ายเมื่อถูกรางวัลสูงขึ้น
                                    </p>
                                  </div>
                                </div> -->
                                <div v-if="showInfo">
                                  <div class="-bottom-paragraph">
                                    <img
                                      :src="
                                        settings && settings.logo
                                          ? settings.logo
                                          : logo_path
                                      "
                                      alt="logo-power100"
                                      width="200"
                                    />
                                    <h3 class="-text mt-2">
                                      <span class="-highlight">Power</span>
                                      เว็บที่ให้บริการคาสิโนออนไลน์เต็มรูปแบบ
                                      <br />
                                      ทั้งสล็อตออนไลน์ คาสิโนสด บาคาร่าสด
                                      เกมแทงปลา เกมกีฬา ฯลฯ
                                    </h3>
                                    <p class="-text -paragraph -h3">
                                      สามารถทำการฝาก-ถอนได้ตลอด 24 ชั่วโมง
                                      ด้วยระบบออโต้ ทั้งสะดวก
                                      รวดเร็วทันใจเป็นอย่างมากเหมาะสำหรับผู้ที่ชื่นชอบการเดิมพันในรูปแบบของเกมการพนันออนไลน์อันดับ
                                      1 ในปี 2022
                                    </p>

                                    <swiper
                                      class="swiper-autoplay mt-2 rounded-3 d-none d-md-block d-lg-block"
                                      :options="swiperOptions"
                                      :dir="
                                        $store.state.appConfig.isRTL
                                          ? 'rtl'
                                          : 'ltr'
                                      "
                                      v-if="
                                        settings &&
                                        settings.slides &&
                                        settings.slides.length > 0
                                      "
                                    >
                                      <swiper-slide
                                        v-for="(data, index) in settings.slides"
                                        :key="index"
                                      >
                                        <b-img
                                          :src="data"
                                          fluid
                                          style="border-radius: 10px"
                                        />
                                      </swiper-slide>
                                    </swiper>
                                  </div>
                                </div>

                                <div class="-games-list-container container">
                                  <nav id="navbarProvider" class="nav-menu">
                                    <ul class="nav nav-pills">
                                      <div class="m-1 m-md-4" v-if="maintain">
                                        <b-alert
                                          class="p-1 p-md-2 w-100"
                                          show
                                          variant="warning"
                                        >
                                          <h4 class="alert-heading">
                                            แจ้งเตือน!
                                          </h4>
                                          <p>
                                            ระบบเกมกำลังปิดปรับปรุงอยู่ในขณะนี้
                                            กรุณาลองใหม่ภายหลังค่ะ.
                                          </p>
                                          <hr />
                                        </b-alert>
                                      </div>
                                      <li
                                        v-else
                                        v-for="games in gameList"
                                        :key="games.id"
                                        class="nav-item -game-casino-macro-container ng-star-inserted"
                                      >
                                        <div
                                          v-if="games.type === 'FISHING'"
                                          class="x-game-list-item-macro js-game-list-toggle -big"
                                          @click="playGame(games)"
                                        >
                                          <div class="-inner-wrapper">
                                            <img
                                              class="-cover-img img-fluid ls-is-cached lazyloaded"
                                              :src="games.img"
                                              :alt="games.name"
                                            />
                                            <div class="-overlay">
                                              <div class="-overlay-inner">
                                                <div class="-wrapper-container">
                                                  <a
                                                    class="-btn -btn-play js-account-approve-aware"
                                                    @click="playGame(games)"
                                                    ><i
                                                      class="fas fa-play"
                                                    /><span class="-text-btn"
                                                      >เข้าเล่น</span
                                                    ></a
                                                  >
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div class="-title text-center">
                                            {{ games.name }}
                                          </div>
                                        </div>

                                        <div
                                          v-else
                                          class="x-game-list-item-macro js-game-list-toggle -big"
                                          @click="playGame(games)"
                                        >
                                          <div class="-inner-wrapper">
                                            <img
                                              class="-cover-img bg-back img-fluid ls-is-cached lazyloaded"
                                              :src="`/games/${games.img}`"
                                              :alt="games.productName"
                                            />
                                            <div class="-overlay">
                                              <div class="-overlay-inner">
                                                <div class="-wrapper-container">
                                                  <a
                                                    class="-btn -btn-play js-account-approve-aware"
                                                    @click="playGame(games)"
                                                    ><i
                                                      class="fas fa-play"
                                                    /><span class="-text-btn"
                                                      >เข้าเล่น</span
                                                    ></a
                                                  >
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div class="-title text-center">
                                            {{ games.productName }}
                                          </div>
                                        </div>
                                      </li>
                                    </ul>
                                  </nav>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div class="-main-footer-container js-replace-main-footer-container">
            <div>
              <div class="x-footer">
                <div class="container-fluid -footer-wrapper">
                  <div class="-footer-inner-wrapper">
                    <div class="-describe-wrapper">
                      <div class="-content-wrapper">
                        <h4
                          class="-title"
                          :style="{
                            color:
                              settings && settings.title_color
                                ? settings.title_color.hex
                                : '',
                          }"
                        >
                          คาสิโนออนไลน์ ที่ดีที่สุด<br />ต้องพัฒนาอย่างใส่ใจทุกคลิ๊ก
                        </h4>
                        <h4 class="-content">
                          บาคาร่า คาสิโนออนไลน์ ที่ดีที่สุด
                          เพื่อประสบการณ์ที่ดีของผู้เล่นอย่างแท้จริง แบบ Casino
                        </h4>
                      </div>
                      <div class="-banner-icon-wrapper">
                        <img
                          src="@/assets/newImg/ico/ระบบแรงกว่ามาตรฐานสากล-3-เท่า.png"
                          alt="ระบบคาสิโนปลอดภัย แรงกว่ามาตรฐาน 3 เท่า"
                          data-animatable="fadeInUp"
                          data-delay="200"
                          class="-ic-banner animated fadeInUp"
                        /><img
                          src="@/assets/newImg/ico/ฝ่ายบริการลูกค้า-24-7-ชม.png"
                          alt="คาสิโนออนไลน์ที่บริการลูกค้า 24 ชม."
                          data-animatable="fadeInUp"
                          data-delay="400"
                          class="-ic-banner animated fadeInUp"
                        /><img
                          src="@/assets/newImg/ico/ระบบฝากถอนเงินออโต้-อัตโนมัติ.png"
                          alt="ฝากถอนเงิน และเครดิตฟรีอัตโนมัติ"
                          data-animatable="fadeInUp"
                          data-delay="600"
                          class="-ic-banner animated fadeInUp"
                        />
                      </div>
                    </div>
                    <div
                      class="-tag-wrapper d-none d-lg-block d-xl-block d-xxl-block"
                    >
                      <div class="-content-wrapper">
                        <div
                          class="-title"
                          :style="{
                            color:
                              settings && settings.title_color
                                ? settings.title_color.hex
                                : '',
                          }"
                        >
                          TAG
                        </div>
                        <div class="container x-footer-seo">
                          <div class="-tags">
                            <a href="/" class="btn"> casino online</a
                            ><a href="/" class="btn"> บาคาร่า</a
                            ><a href="/" class="btn"> คาสิโน</a
                            ><a href="/" class="btn"> gamecasino</a
                            ><a href="/" class="btn"> ออนไลน์ คาสิโน</a
                            ><a href="/" class="btn"> game casino</a
                            ><a href="/" class="btn"> ae sexy</a
                            ><a href="/" class="btn"> เว็บคาสิโน</a
                            ><a href="/" class="btn"> ป๊อก เด้ง</a
                            ><a href="/" class="btn"> dg casino</a
                            ><a href="/" class="btn"> wm casino</a
                            ><a href="/" class="btn"> allbet</a
                            ><a href="/" class="btn"> sexy gaming</a
                            ><a href="/" class="btn"> sa gaming</a
                            ><a href="/" class="btn"> คาสิโนออนไลน์</a
                            ><a href="/" class="btn"> บาคาร่าสด</a
                            ><a href="/" class="btn"> เครดิตฟรี</a
                            ><a href="/" class="btn"> บาคาร่า</a
                            ><a href="/" class="btn"> เสือ มังกร</a
                            ><a href="/" class="btn"> บาคาร่าออนไลน์</a
                            ><a href="/" class="btn"> เกมไพ่</a
                            ><a href="/" class="btn"> sagame</a
                            ><a href="/" class="btn"> คาสิโน</a
                            ><a href="/" class="btn"> casino</a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="-provider-wrapper">
                      <div class="-content-wrapper -payments">
                        <div
                          class="-title"
                          :style="{
                            color:
                              settings && settings.title_color
                                ? settings.title_color.hex
                                : '',
                          }"
                        >
                          PAYMENTS ACCEPTED
                        </div>
                        <div class="x-footer-banner-provider">
                          <div class="-image-wrapper ng-star-inserted">
                            <img
                              alt="CasinoPayments Accepted bay"
                              width="50"
                              height="50"
                              class="-logo-ic img-fluid rounded-circle"
                              src="@/assets/newImg/bank/bbl.png"
                            />
                          </div>
                          <div class="-image-wrapper ng-star-inserted">
                            <img
                              alt="CasinoPayments Accepted bay"
                              width="50"
                              height="50"
                              class="-logo-ic img-fluid rounded-circle"
                              src="@/assets/newImg/bank/kbank.png"
                            />
                          </div>
                          <div class="-image-wrapper ng-star-inserted">
                            <img
                              alt="CasinoPayments Accepted bay"
                              width="50"
                              height="50"
                              class="-logo-ic img-fluid rounded-circle"
                              src="@/assets/newImg/bank/ktb.png"
                            />
                          </div>
                          <div class="-image-wrapper ng-star-inserted">
                            <img
                              alt="CasinoPayments Accepted bay"
                              width="50"
                              height="50"
                              class="-logo-ic img-fluid rounded-circle"
                              src="@/assets/newImg/bank/tmb.png"
                            />
                          </div>
                          <div class="-image-wrapper ng-star-inserted">
                            <img
                              alt="CasinoPayments Accepted bay"
                              width="50"
                              height="50"
                              class="-logo-ic img-fluid rounded-circle"
                              src="@/assets/newImg/bank/scb.png"
                            />
                          </div>
                          <div class="-image-wrapper ng-star-inserted">
                            <img
                              alt="CasinoPayments Accepted bay"
                              width="50"
                              height="50"
                              class="-logo-ic img-fluid rounded-circle"
                              src="@/assets/newImg/bank/cimb.png"
                            />
                          </div>
                          <div class="-image-wrapper ng-star-inserted">
                            <img
                              alt="CasinoPayments Accepted bay"
                              width="50"
                              height="50"
                              class="-logo-ic img-fluid rounded-circle"
                              src="@/assets/newImg/bank/uob.png"
                            />
                          </div>
                          <div class="-image-wrapper ng-star-inserted">
                            <img
                              alt="CasinoPayments Accepted bay"
                              width="50"
                              height="50"
                              class="-logo-ic img-fluid rounded-circle"
                              src="@/assets/newImg/bank/bay.png"
                            />
                          </div>
                          <div class="-image-wrapper ng-star-inserted">
                            <img
                              alt="CasinoPayments Accepted bay"
                              width="50"
                              height="50"
                              class="-logo-ic img-fluid rounded-circle"
                              src="@/assets/newImg/bank/gsb.png"
                            />
                          </div>
                          <div class="-image-wrapper ng-star-inserted">
                            <img
                              alt="CasinoPayments Accepted bay"
                              width="50"
                              height="50"
                              class="-logo-ic img-fluid rounded-circle"
                              src="@/assets/newImg/bank/BAAC.png"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="-footer-bottom-wrapper">
                    <div class="row justify-content-center m-0">
                      <div class="col-md-6 text-center mt-4">
                        Created website by Power100th
                        <div class="text-center ng-star-inserted">
                          <!-- <a
                            href="https://power100th.com/"
                            target="_blank"
                            rel="nofollow noopener"
                            class="-img-wrap -img-wrap-link text-decoration-none"
                          ><img
                            src="@/assets/newImg/logo.png"
                            alt="karnival company entry"
                            width="150"
                            class="-button-bg"
                          ></a> -->
                        </div>
                      </div>
                    </div>
                    <div>
                      <a
                        href="/term-and-condition"
                        target="_blank"
                        class="-term-btn"
                      >
                        Terms and Conditions
                      </a>
                    </div>
                    <div>
                      <p class="mb-0">Copyright © 2021 All Rights Reserved.</p>
                      <p class="opacity-50 ng-star-inserted">
                        <small>(v.1.3.3)</small>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <this-footer />
        </div>
      </div>
    </div>

    <div id="app">
      <!-- Popup overlay and content -->
      <div v-if="isPopupVisible" class="popup-overlay" @click="hidePopup">
        <div class="popup-content" @click.stop>
          <span class="close" @click="hidePopup">
            <i class="fas fa-times"></i>
          </span>
          <img
            :src="imageUrl"
            alt="Announcement Image"
            class="announcement-image"
          />
          <div class="announcement-text">
            <h1>{{ title }}</h1>
            <p>{{ description }}</p>
          </div>
        </div>
      </div>
    </div>

    <b-modal
      ref="my-modal"
      hide-footer
      centered
      hide-header
      class="modals"
      style="background-color: transparent !important"
    >
      <div class="text-center">
        <router-link :to="{ name: 'promotions' }">
          <b-img src="/modal/songkran.png" alt="songkran" fluid />
        </router-link>
        <button class="btn-closed" @click="hidenModal">
          <i class="fas fa-times" />
        </button>
      </div>
    </b-modal>
  </div>
</template>
<script>
// import { BCard, BCardText, BLink } from 'bootstrap-vue'
import { BImg, BModal, BAlert } from "bootstrap-vue";
import moment from "moment";
import "swiper/css/swiper.css";
import BUID from "uniquebrowserid";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
// import { ThisFooter } from './ThisFooter.vue'
// import { t } from '@/@core/libs/i18n/utils'
import store from "@/store";

export default {
  components: {
    Swiper,
    SwiperSlide,
    BImg,
    BModal,
    BAlert,
    // BCard,
    // BCardText,
    // BLink,
    // ThisFooter,
  },
  computed: {
    settings() {
      return store.state.app.settings;
    },
  },
  data() {
    return {
      isPopupVisible: false, // ตั้งค่าเริ่มต้นเป็น true เพื่อให้ popup โชว์เมื่อเปิดหน้า
      imageUrl: "path/to/your/image.jpg",
      title: "ประกาศ",
      description: "นี่คือข้อความประกาศที่คุณต้องการแสดง",
      activeMenu1: false,
      activeMenu2: false,
      activeMenu3: false,
      activeMenu4: false,
      activeMenu5: false,
      activeMenu6: false,
      activeMenu7: false,
      activeMenu8: false,
      showInfo: true,
      gameListOp: [
        /* eslint-disable global-require */
        {
          icon: require("@/assets/newImg/slot.png"),
          name_th: "สล็อตเกม",
          name_en: "SLOT",
          alt: "สล็อตเกม",
          cat: "EGAMES",
        },
        {
          icon: require("@/assets/newImg/คาสิโนออนไลน์-บาคาร่าสด-รูเล็ต-ซิคโบ-ไฮโล-เสือ-มังกร.png"),
          name_th: "คาสิโนสด",
          name_en: "CASINO",
          alt: "คาสิโนสด",
          cat: "LIVECASINO",
        },
        {
          icon: require("@/assets/newImg/แทงฟุตบอลพนันออนไลน์-บาสเก็ตบอล-E-Sport.png"),
          name_th: "กีฬา",
          name_en: "SPORT",
          alt: "กีฬา",
          cat: "SPORT",
        },
        {
          icon: require("@/assets/newImg/lotto.png"),
          name_th: "หวย",
          name_en: "LOTTO",
          alt: "หวย",
          cat: "LOTTO",
        },
        {
          icon: require("@/assets/newImg/quick-nav-mpg.png"),
          name_th: "ยิงปลา",
          name_en: "FISHING",
          alt: "ยิงปลา",
          cat: "FISHING",
        },
        {
          icon: require("@/assets/newImg/poker.png"),
          name_th: "เกมการ์ด",
          name_en: "G-CARD",
          alt: "เกมการ์ด",
          cat: "CARD",
        },
        {
          icon: require("@/assets/newImg/poker.png"),
          name_th: "เกมโป๊กเกอร์",
          name_en: "POKER",
          alt: "โป๊กเกอร์",
          cat: "POKER",
        },
        {
          icon: require("@/assets/newImg/trading.png"),
          name_th: "เทรดดิ้ง",
          name_en: "TRADING",
          alt: "เทรดดิ้ง",
          cat: "TRADING",
        },
        /* eslint-disable global-require */
      ],
      gameList: [],
      gameListL: [],
      agent: {},
      swiperOptions: {
        centeredSlides: true,
        autoplay: {
          delay: 2500,
          disableOnInteraction: false,
        },
      },
      /* eslint-disable global-require */
      swiperData: [
        { img: "/slider/ชวนเพื่อน10เปอร์เซ็นต์_power100.jpg" },
        { img: "/slider/คืนยอดเสีย_power100.jpg" },
        { img: "/slider/สมัครใหม่100เปอร์เซ็นต์_power100.jpg" },
        { img: "/slider/สมัครใหม่50เปอร์เซ็นต์_power100.jpg" },
        { img: "/slider/ทุกยอดฝาก10เปอร์เซ็นต์_power100.jpg" },
        { img: "/slider/ฝากบิลแรก20เปอร์เซ็นต์_power100.jpg" },
      ],
      /* eslint-disable global-require */
      userData: JSON.parse(localStorage.getItem("userData")),
      balance: 0.0,
      turnpro: "",
      turn: "",
      today: moment(new Date()).format("DD/MM/YYYY"),
      timenow: moment(new Date()).format("HH:mm"),
      message: "asdasd",
      User: "",
      selectCategory: false,
      logo_path: localStorage.getItem("logo_path"),
      checkstatus: "",
      maintain: false,
    };
  },
  mounted() {
    // this.openModal()
    this.GetAgentData();
    this.getbalance();
    this.lastlogin();
    this.loginstampAtdays();
    this.updateBUID();
    this.statusUser();
    // this.showModal()
  },
  methods: {
    hidePopup() {
      this.isPopupVisible = false;
    },
    statusUser() {
      this.$http
        .get(`/users/show/${this.userData.userID}`)
        .then((response) => {
          this.checkstatus = response.data.status;
        })
        .catch((error) => console.log(error));
    },
    // showModal() {
    //   this.$refs['my-modal'].show()
    // },
    hidenModal() {
      this.$refs["my-modal"].hide();
    },
    getbalance() {
      this.show = true;
      this.$http
        .get("/balance/getbalance")
        .then((response) => {
          console.log(response.data);
          this.show = false;
          this.balance = response.data.balance;
          if (this.balance < 0) {
            this.balance = 0;
          }
          this.turn = response.data;
        })
        .catch((error) => console.log(error));
    },
    CheckPass() {
      if (btoa(this.password) === "UEBwb3dlcjEwMA==") {
        this.LottoLogin();
      }
    },
    LottoLogin() {
      this.show = true;
      const obj = {};
      this.$http
        .post("/rmtlotto/login", obj)
        .then((response) => {
          console.log(response.data);
          this.show = false;
          window.location.href = response.data.url;
        })
        .catch((error) => console.log(error));
    },
    play(productId, gameCode) {
      // console.log(this.$route.params.productId)88
      const obj = {
        username: this.userData.username,
        productId,
        gameCode,
        sessionToken: this.userData.token,
      };
      this.$http
        .post("/product/login", obj)
        .then((response) => {
          // console.log(response.data.data.url)
          this.show = false;
          window.location.href = response.data.data.url;
        })
        .catch((error) => console.log(error));
    },
    playGame(games) {
      // console.debug(games)
      if (this.checkstatus && this.checkstatus === 2) {
        this.$swal({
          icon: "info",
          title: '<h3 style="color: #FFF">แจ้งเตือน !</h3>',
          text: "บัญชีของท่านถูกระงับ",
          showConfirmButton: false,
          timer: 5000,
          background: "#202124",
        });
      } else if (games.type === "FISHING") {
        this.play(games.productId, games.code);
      } else if (
        this.turn.promotionID != null &&
        this.turn.promotionID !== 10
      ) {
        if (games.category === "EGAMES") {
          this.$router.push({
            name: "games-all",
            params: {
              productId: games.productId,
              productName: games.productName,
            },
          });
        } else {
          this.SwalError(
            "ท่านมีโปรฯที่ยังทำเทิร์นไม่ครบ ไม่สามารถเล่นเกมนี้ได้ค่ะ"
          );
        }
      } else if (games.category === "SPORT") {
        if (games.productId === "SBO") {
          this.show = true;
          // const obj = {};
          // this.$http
          //   .post("/sbobet/login", obj)
          //   .then((response) => {
          //     console.log(response.data);
          //     this.show = false;
          //     window.location.href = response.data.url;
          //   })
          //   .catch((error) => console.log(error));
          const obj = {
            username: this.userData.username,
            productId: games.productId,
            gameCode: "SportsBook",
            sessionToken: this.userData.token,
          };
          this.$http
            .post("/product/login", obj)
            .then((response) => {
              // console.log(response.data.data.url)
              this.show = false;
              window.location.href = response.data.data.url;
            })
            .catch((error) => console.log(error));

          // this.SwalError('พบกับ SBOBET ที่นี่เร็วๆนี้')
        }
        if (games.productId === "E1SPORT") {
          this.play(games.productId, "E1-ESPORTS-001");
        }
      } else if (games.category === "LOTTO") {
        if (games.productId === "LOTTO") {
          // Modal
          // this.showModal()
          this.LottoLogin();
        }
      } else {
        this.$router.push({
          name: "games-all",
          params: {
            productId: games.productId,
            productName: games.productName,
          },
        });
      }
    },
    getProductAll() {
      this.initvalue();
      this.$http
        .get("/product/listall")
        .then((response) => {
          // console.log(response.data)
          this.show = false;
          this.gameList = response.data;
        })
        .catch((error) => console.log(error));
      this.$http
        .get("/product/listalll")
        .then((response) => {
          // console.log(response.data)
          this.show = false;
          this.gameListL = response.data;
        })
        .catch((error) => console.log(error));
    },
    getProduct(category) {
      this.showInfo = false;
      console.log(category);
      // console.log(this.agent.game_visible);

      switch (category) {
        case "EGAMES":
          this.maintain = this.agent.game_visible.slot !== 1;
          break;
        case "LIVECASINO":
          this.maintain = this.agent.game_visible.casino !== 1;
          break;
        case "SPORT":
          this.maintain = this.agent.game_visible.sport !== 1;
          break;
        case "LOTTO":
          this.maintain = this.agent.game_visible.lotto !== 1;
          break;
        case "FISHING":
          this.maintain = this.agent.game_visible.fishing !== 1;
          break;
        case "CARD":
          this.maintain = this.agent.game_visible.card !== 1;
          break;
        case "POKER":
          this.maintain = this.agent.game_visible.poker !== 1;
          break;
        case "TRADING":
          this.maintain = this.agent.game_visible.trading !== 1;
          break;
      }

      if (!this.maintain) {
        this.initvalue();
        this.$http
          .get(
            `/product/listcat/${category}?agent_id=${this.userData.agent_id}`
          )
          .then((response) => {
            // console.log(response.data)
            this.show = false;
            this.gameList = response.data;
          })
          .catch((error) => console.log(error));
        this.$http
          .get(
            `/product/listcatl/${category}?agent_id=${this.userData.agent_id}`
          )
          .then((response) => {
            // console.log(response.data)
            this.show = false;
            this.gameListL = response.data;
          })
          .catch((error) => console.log(error));
      }
    },
    GetAgentData() {
      this.$http
        .get("/agent/show")
        .then((response) => {
          // console.log(response.data)
          this.agent = response.data;
          if (this.agent.announce_member_status == 1) {
            this.isPopupVisible = true;
          } else {
            this.isPopupVisible = false;
          }
          // ตั้งค่าเริ่มต้นเป็น true เพื่อให้ popup โชว์เมื่อเปิดหน้า
          this.imageUrl = this.agent.announce_member_pic;
          this.description = this.agent.announce_member;
        })
        .catch((error) => console.log(error));
    },

    updateBUID() {
      const buid = new BUID().completeID();
      const formData = {
        buid,
      };
      this.$http.post("/users/updatebuid", formData);
    },
    lastlogin() {
      this.$http
        .get("/users/lastlogin")
        // eslint-disable-next-line no-unused-vars
        .then((response) => {})
        .catch((error) => console.log(error));
    },
    loginstampAtdays() {
      this.$http
        .get("/users/loginstampAtdays")
        // eslint-disable-next-line no-unused-vars
        .then((response) => {})
        .catch((error) => console.log(error));
    },
    // getbalance() {
    //   this.show = true
    //   this.$http
    //     .get('/balance/getbalance')
    //     .then(response => {
    //       // console.log(response.data)
    //       this.show = false
    //       this.balance = response.data.balance
    //       if (this.balance < 0) {
    //         this.balance = 0
    //       }
    //       this.turn = response.data
    //     })
    //     .catch(error => console.log(error))
    // },
    toggleActive(cat) {
      if (cat === "EGAMES") {
        this.activeMenu1 = !this.activeMenu1;
        this.activeMenu2 = false;
        this.activeMenu3 = false;
        this.activeMenu4 = false;
        this.activeMenu5 = false;
        this.activeMenu6 = false;
        this.activeMenu7 = false;
        this.activeMenu8 = false;
      } else if (cat === "LIVECASINO") {
        this.activeMenu2 = !this.activeMenu2;
        this.activeMenu1 = false;
        this.activeMenu3 = false;
        this.activeMenu4 = false;
        this.activeMenu5 = false;
        this.activeMenu6 = false;
        this.activeMenu7 = false;
        this.activeMenu8 = false;
      } else if (cat === "SPORT") {
        this.activeMenu3 = !this.activeMenu3;
        this.activeMenu1 = false;
        this.activeMenu2 = false;
        this.activeMenu4 = false;
        this.activeMenu5 = false;
        this.activeMenu6 = false;
        this.activeMenu7 = false;
        this.activeMenu8 = false;
      } else if (cat === "LOTTO") {
        this.activeMenu4 = !this.activeMenu4;
        this.activeMenu1 = false;
        this.activeMenu2 = false;
        this.activeMenu3 = false;
        this.activeMenu5 = false;
        this.activeMenu6 = false;
        this.activeMenu7 = false;
        this.activeMenu8 = false;
      } else if (cat === "FISHING") {
        this.activeMenu5 = !this.activeMenu5;
        this.activeMenu1 = false;
        this.activeMenu2 = false;
        this.activeMenu3 = false;
        this.activeMenu4 = false;
        this.activeMenu6 = false;
        this.activeMenu7 = false;
        this.activeMenu8 = false;
      } else if (cat === "CARD") {
        this.activeMenu6 = !this.activeMenu6;
        this.activeMenu1 = false;
        this.activeMenu2 = false;
        this.activeMenu3 = false;
        this.activeMenu4 = false;
        this.activeMenu5 = false;
        this.activeMenu7 = false;
        this.activeMenu8 = false;
      } else if (cat === "TRADING") {
        this.activeMenu7 = !this.activeMenu7;
        this.activeMenu1 = false;
        this.activeMenu2 = false;
        this.activeMenu3 = false;
        this.activeMenu4 = false;
        this.activeMenu5 = false;
        this.activeMenu6 = false;
        this.activeMenu8 = false;
      } else if (cat === "POKER") {
        this.activeMenu8 = !this.activeMenu8;
        this.activeMenu7 = false;
        this.activeMenu1 = false;
        this.activeMenu2 = false;
        this.activeMenu3 = false;
        this.activeMenu4 = false;
        this.activeMenu5 = false;
        this.activeMenu6 = false;
      }
    },
    comingSoon() {
      this.$swal({
        icon: "info",
        title: '<h3 style="color: #FFF">Coming soon!</h3>',
        showConfirmButton: false,
        timer: 1500,
        background: "#202124",
      });
    },
    SwalError(mes) {
      this.$swal({
        icon: "error",
        title: '<h3 style="color:#000">ขออภัยค่ะ!<span>',
        text: mes,
        customClass: {
          confirmButton: "btn btn-success",
        },
      });
    },
    initvalue() {
      this.gameList = [];
      this.gameListL = [];
    },
  },
};
</script>
<style scoped>
.bg-back {
  background-image: url("/bg-game.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
#app {
  text-align: center;
  margin-top: 50px;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1035;
}

.popup-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 100%;
  max-width: 576px;
  margin: 0 10px;
  text-align: center;
  position: relative;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.announcement-image {
  max-width: 100%;
  max-height: auto;
  height: auto;
  width: auto;
  border-radius: 8px;
}

.announcement-text {
  margin-top: 16px;
  color: black; /* ตั้งค่าสีของข้อความเป็นสีดำ */
}

.announcement-text h1 {
  font-size: 1.5em;
  margin-bottom: 8px;
  color: black; /* ตั้งค่าสีของข้อความเป็นสีดำ */
}

.announcement-text p {
  font-size: 1em;
  color: #555;
  color: black; /* ตั้งค่าสีของข้อความเป็นสีดำ */
}

.close {
  position: absolute;
  height: 30px;
  width: 30px;
  top: 10px;
  right: 10px;
  border-radius: 7px;
  cursor: pointer;
  font-size: 23px;
  background: #747474;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1 !important;
}
</style>
